import styled from 'styled-components'
import { useDocument } from '@sb-konzept/firebase-hooks'
import { useContext, useEffect, useState, useRef, useMemo } from 'react'
import Collapse from '@material-ui/core/Collapse'
import MealContext from 'components/MealContext'
import useUserData from 'hooks/useUserData'
import OwnMealImage from 'images/ownmealimage.png'
import AddPlaceholder from '@material-ui/icons/AddPhotoAlternateOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/EditOutlined'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import Link from 'next/link'
import Close from 'images/close.png'
import Card from 'components/Card'
import { Router, useRouter } from 'next/router'

const MealAdderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
`

const ImageBox = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 10rem;
  color: #939393;
  background: #2c2c2c;
  border-radius: 10px;
  > img {
    object-fit: cover;
    border-radius: 10px;
  }
`

const Ingredients = styled.div`
  hr {
    margin: 0.5rem 0;
  }
`
const IngredientsTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px 2px -10px;
  span {
    margin: 0 10px;
    color: black;
    font-weight: bold;
    font-size: 12px;
    :last-child {
      text-align: right;
    }
  }
`
const IngredientsBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  span {
    margin: 5px 5px 0 5px;
    color: #939393;
    font-size: 10px;
  }
`

const MealImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const MealTypeBox = styled.div`
  flex: 2;
  background: #fff;
  /*margin-left: -3rem;*/
  border-radius: 10px;
  padding: 12px;
`

const MealAdderTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem 0.2rem 0;
`

const MealType = styled.h3`
  color: #8e8e93;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
`

const Stats = styled.div`
  padding-top: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  color: #000;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 1rem;
`

const MealAdderBottom = styled.div`
  padding: 0.2rem 0.5rem 0.2rem 0;
  display: flex;
  justify-content: space-between;
`

const MealName = styled.h3`
  position: relative;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  color: black;
`

const Row = styled.div`
  display: flex;
`

const Col = styled.div`
  p {
    font-weight: bold;
    color: #842008;
    font-size: 16px;
    margin-bottom: 3px;
  }
  span {
    color: #939393;
    font-size: 12px;
  }
`

const Arrow = styled.div`
  position: absolute;
  top: ${(props) => (props.open ? 2 : -2)}px;
  right: -15px;
  transform: rotate(${(props) => (props.open ? -45 : -225)}deg);
  border-top: 2px solid #842008;
  border-right: 2px solid #842008;
  height: 8px;
  width: 8px;
  transition: all 300ms ease-in-out;
`

const Ingredient = styled.div`
  background: #ddd;
  border-radius: 10px;
  padding: 16px;
  margin: 5px 0;
`

const Backdrop = styled.div`
  position: relative;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`

const Modal = styled(Card)`
  border: 1px solid #707070;
  position: relative;
  width: 80%;
  max-height: 75vh;
  padding-top: 3rem;
  background: #2c2c2c;
`

const CloseButton = styled.div`
  position: absolute;
  z-index: 1000;
  width: 1rem;
  height: 1rem;
  right: 10px;
  top: 10px;
  border-radius: 50%;
`

const MealButton = styled.a`
  text-transform: capitalize;
  border-radius: 10px;
  font-size: 1.063rem;
  font-weight: 700;
  color: white;
  height: 2.813rem;
  background: #606060;
  margin: 0.5rem 0;
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span {
    color: #939393;
    font-size: 0.8rem;
  }
`

export function Meal({
  meal,
  title,
  onMore,
  onAdd,
  bigAdd,
  onItemEdit,
  open: openProp
}) {
  const [open, setOpen] = useState()

  const total = meal?.reduce(
    (acc, val) => {
      let multiplier = val.amount * val.units?.[val.unit]
      if (isNaN(multiplier)) multiplier = 1
      return {
        calories: acc.calories + (val.calories * multiplier || 0),
        fat: acc.fat + (val.fat * multiplier || 0),
        carbs: acc.carbs + (val.carbs * multiplier || 0),
        protein: acc.protein + (val.protein * multiplier || 0)
      }
    },
    { calories: 0, fat: 0, carbs: 0, protein: 0 }
  )

  return (
    <MealAdderWrapper>
      <MealTypeBox>
        <Row>
          <Col
            style={{ flex: 1 }}
            {...(typeof openProp !== 'boolean' && {
              onClick: () => setOpen((prev) => !prev)
            })}
          >
            <MealAdderBottom>
              <MealName>
                {title}
                {meal?.length > 0 && typeof openProp !== 'boolean' && (
                  <Arrow open={open} />
                )}
              </MealName>
            </MealAdderBottom>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '-12px',
              color: 'black'
            }}
          >
            {onMore && (
              <IconButton onClick={onMore}>
                <MoreIcon />
              </IconButton>
            )}
            {onAdd && !bigAdd && (
              <IconButton
                // onClick={() => {
                //   setState(prev => ({ ...prev, meal: meal.ref }))
                //   Router.push(`/ownmeal?load=true`)
                // }}
                onClick={onAdd}
              >
                <AddIcon />
              </IconButton>
            )}
          </Col>
        </Row>
        {meal?.length > 0 && (
          <Stats>
            <Col>
              <p>{(total?.calories || 0).toFixed(0)}</p>
              <span>Calories</span>
            </Col>
            <Col>
              <p>{(total?.protein || 0).toFixed(0)}</p>
              <span>Protein</span>
            </Col>
            <Col>
              <p>{(total?.carbs || 0).toFixed(0)}</p>
              <span>Carbs</span>
            </Col>
            <Col>
              <p>{(total?.fat || 0).toFixed(0)}</p>
              <span>Fat</span>
            </Col>
          </Stats>
        )}
        <Collapse in={openProp || open}>
          <Ingredients>
            {meal?.map((item, idx) => {
              let multiplier = item.amount * item.units?.[item.unit]
              if (isNaN(multiplier)) multiplier = 1
              return (
                <Ingredient
                  {...(onItemEdit && { onClick: () => onItemEdit(item, idx) })}
                >
                  <IngredientsTop>
                    <span>{item.title}</span>
                    <span>
                      {[item.amount, item.unit].filter(Boolean).join(' ')}
                    </span>
                  </IngredientsTop>
                  <IngredientsBottom>
                    <span>
                      Calories: {(item?.calories * multiplier || 0).toFixed(0)}
                    </span>
                    <span>
                      Protein: {(item?.protein * multiplier || 0).toFixed(0)}
                    </span>
                    <span>
                      Carbs: {(item?.carbs * multiplier || 0)?.toFixed(0)}
                    </span>
                    <span>Fat: {(item?.fat * multiplier || 0).toFixed(0)}</span>
                  </IngredientsBottom>
                </Ingredient>
              )
            })}
          </Ingredients>
        </Collapse>

        {onAdd && bigAdd && <MealButton onClick={onAdd}>+ Add Food</MealButton>}
      </MealTypeBox>
    </MealAdderWrapper>
  )
}

function MealAdder(props) {
  const { mealtype, extra, setData } = props
  const [modal, setModal] = useState(false)
  const router = useRouter()
  const {
    deleteMeal,
    meals,
    pickMeal,
    setState,
    date,
    setSearchmodal,
    addFood
  } = useContext(MealContext)
  const meal = meals?.[mealtype.id]
  const { value } = useUserData()

  return (
    <>
      {modal && (
        <Backdrop>
          <Modal>
            <CloseButton onClick={() => setModal(false)}>
              <img
                src={Close}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </CloseButton>
            <MealButton
              style={{ background: 'black' }}
              onClick={() => {
                setModal(false)
                setSearchmodal({
                  ...modal.item,
                  onSuccess: (r) => {
                    addFood(
                      { ...r, amount: Number(r.amount) },
                      null,
                      modal.idx,
                      mealtype
                    )
                  }
                })
              }}
            >
              Edit item
            </MealButton>
            <MealButton
              onClick={() => {
                setModal(false)
                value?.ref
                  .collection('meals')
                  .doc(date.format('YYYY-MM-DD'))
                  .set(
                    {
                      [mealtype.id || mealtype.title]: meal.filter(
                        (x, i) => i !== modal.idx
                      )
                    },
                    { merge: true }
                  )
              }}
            >
              Remove item
            </MealButton>
          </Modal>
        </Backdrop>
      )}
      <Meal
        bigAdd
        meal={meal}
        title={mealtype?.Title}
        onAdd={() => {
          setState((prev) => ({ ...prev, mealtype }))
          router.push('/add-food')
        }}
        onItemEdit={(item, idx) => {
          console.log(item)
          if (item.ingredients) {
            router.push(
              `/ownmeal?load=true&meal=${mealtype.id
              }&idx=${idx}&date=${date.format('YYYY-MM-DD')}`
            )
            return
          }
          setModal({ item, idx })
        }}
        onMore={() => pickMeal(mealtype)}
      />
    </>
  )
}

export default MealAdder
