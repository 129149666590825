import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Router from 'next/router'
import useUserData from 'hooks/useUserData'
import {
  useCollection,
  useDocument,
  useFirebase
} from '@sb-konzept/firebase-hooks'
import Select from 'components/Select'
import moment from 'moment'
import Close from 'images/close.png'
import Card from 'components/Card'
import { Meal } from 'components/MealAdder'

const Context = React.createContext({})
export default Context

const Headline = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
`

const ModalInputField = styled.input`
  width: ${({ width }) => width || '100%'};
  padding: 12px;
  border-radius: 10px;
  background-color: #2c2c2c;
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  box-sizing: border-box;
  text-align: ${(props) => props.textAlign || 'center'};
  color: #939393;
  border: none;
  &::placeholder {
    line-height: 20px;
    font-size: 13px;
    font-weight: bold;
    color: #939393;
    text-align: ${(props) => props.textAlign || 'left'};
  }
  ${({ label }) => (label ? 'margin-top: 12px;' : '')}
  margin: 0.5rem 0;
`

const ProgressButton = styled.button`
  border: 0px;
  width: 100%;
  height: 2.813rem;
  background: #842008;
  color: white;
  font-weight: 700;
  font-size: 1.063rem;
  margin: 35px 0 15px 0;
  border-radius: 10px;
  border: 0;
  margin-top: 10px;
`
const MealItemName = styled.h3`
  font-size: 10px;
  font-weight: 200;
  color: #939393;
`

const IngredientsBottom = styled.div`
  width: ${({ width }) => width || '100%'};
  padding: 12px;
  border-radius: 10px;
  background-color: #2c2c2c;
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  box-sizing: border-box;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`

const Backdrop = styled.div`
  position: relative;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`

const Modal = styled.div`
  position: relative;
  background-color: #101010;
  width: calc(100% - 77px);
  max-height: 75vh;
  padding: 50px 16px 30px 16px;
  border-radius: 12px;
  border: 1px solid #939393;
  overflow-y: auto;
`

const CloseButton = styled.div`
  position: absolute;
  z-index: 1000;
  width: 1rem;
  height: 1rem;
  right: 10px;
  top: 10px;
  border-radius: 50%;
`

const MealButton = styled.a`
  text-transform: capitalize;
  border-radius: 10px;
  font-size: 1.063rem;
  font-weight: 700;
  color: white;
  height: 2.813rem;
  background: #606060;
  margin: 0.5rem 0;
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span {
    color: #939393;
    font-size: 0.8rem;
  }
`

function MealTypeSelectButton({ mealtype, currentMeal, resolve, idx }) {
  const { value } = useDocument(currentMeal)
  return (
    <MealButton
      onClick={() => {
        resolve(mealtype)
      }}
    >
      {mealtype.Title}
      {value?.title && <span>(replaces {value?.title})</span>}
    </MealButton>
  )
}

export function MealProvider({ children }) {
  const [selectedMealtype, setMealtype] = useState()
  const [selectedDiettype, setDiettype] = useState()
  const [searchmodal, setSearchmodal] = useState(false)
  const [date, setDate] = useState(moment())
  const firebase = useFirebase()
  const { value, loading } = useUserData()
  const { value: mealtypes = [] } = useCollection('mealtypes', {
    withRef: true
  })
  const { value: diettypes } = useCollection('diettypes', { withRef: true })
  const [pickMealtype, setPickMealtype] = useState()
  const [state, setState] = useState({ tab: 0 })
  const deleteMeal = useCallback(
    async (mealtype) => {
      await value?.ref
        .collection('meals')
        .doc((state.date || date).format('YYYY-MM-DD'))
        .set({ [mealtype.id || mealtype.title]: [] }, { merge: true })
      setState((prevState) => ({ ...prevState, modal: false, mealtype: null }))
    },
    [state, value?.ref, date]
  )
  const calculating = useRef()
  useEffect(() => {
    if (value?.ref && !value?.lastMealCheck) {
      value.ref.set({ lastMealCheck: new Date() })
    }
    let lastMealCheck = moment(value?.lastMealCheck?.toDate())
    if (value?.lastMealCheck && !calculating.current) {
      ; (async () => {
        calculating.current = true
        const activity = { Light: 1.4, Moderate: 1.6, High: 1.8 }[value?.level]
        const goal = { Bulk: 1.1, Recomp: 1, Cut: 0.8 }[value?.shape] || 1
        const gender = value?.gender === 'MALE' ? 5 : -161
        const calories =
          Math.round(
            ((parseFloat(value?.weight) / 0.22 +
              parseFloat(value?.height) * 12 * 6.25 * 2.54 -
              parseFloat(value?.age) * 4.92 +
              gender) *
              activity *
              goal) /
            100
          ) * 100
        const protein = Math.round(parseFloat(value?.weight) * 0.85)
        const fat = Math.round(parseFloat(value?.weight) * 0.4)
        const carbs = Math.round((calories - protein * 4 - fat * 9) / 4 - 20)
        let streak = value?.badges?.mealstreak?.current || 0
        let maxStreak = value?.badges?.mealstreak?.level || 0
        let beastlyRecipes = 0
        while (!moment().isSame(lastMealCheck, 'day')) {
          const meals = value?.meals?.[lastMealCheck.format('YYYY-MM-DD')]
          if (meals) {
            const mealItems = await Promise.all(
              Object.values(meals).map((m) =>
                m.get().then((ss) => ({
                  ...ss.data(),
                  collection: ss.ref.parent.id
                }))
              )
            )
            const used = mealItems.reduce(
              (acc, val) =>
                Object.entries(acc).reduce(
                  (a, [key, v]) => ({ ...a, [key]: v + (val[key] || 0) }),
                  {}
                ),
              { calories: 0, protein: 0, fat: 0, carbs: 0 }
            )
            beastlyRecipes += mealItems.filter(
              (item) => item.collection === 'recipes'
            ).length
            if (
              Math.abs(used.calories - calories) < 100 &&
              used.protein >= protein &&
              Math.abs(used.carbs - carbs + 50) < 50 &&
              Math.abs(used.fat - fat - 15) < 15
            ) {
              streak += 1
              maxStreak = streak
              lastMealCheck = lastMealCheck.add(1, 'day')
              continue
            }
          }
          lastMealCheck = lastMealCheck.add(1, 'day')
          streak = 0
        }
        /* await value.ref.update(
          `badges.mealstreak.level`,
          maxStreak,
          `badges.mealstreak.current`,
          streak,
          `lastMealCheck`,
          lastMealCheck.add(-1, 'day').toDate(),
          `badges.beastlyRecipes.level`,
          FieldValue.increment(beastlyRecipes)
        ) */
        calculating.current = false
      })()
    }
  }, [value?.lastMealCheck])

  const pickRecipe = useCallback(
    async (recipe) => {
      const mealtype =
        state.mealtype ||
        (await new Promise((resolve, reject) =>
          setPickMealtype({ resolve, reject })
        ))
      await value?.ref.update(
        `meals.${(state.date || date).format('YYYY-MM-DD')}.${mealtype.id ||
        mealtype.title}`,
        recipe
      )
      setPickMealtype(null)
      setState({ tab: 0, mealtype: null })
      Router.push('/mealplan')
    },
    [state, setState, date, value?.ref]
  )

  const addFood = useCallback(
    async (food, raw, idx, _mealtype) => {
      console.log({ food, raw, idx, _mealtype })
      const mealtype =
        _mealtype ||
        state.mealtype ||
        (await new Promise((resolve, reject) =>
          setPickMealtype({ resolve, reject })
        ))
      await Promise.all([
        value?.ref
          .collection('meals')
          .doc((state.date || date).format('YYYY-MM-DD'))
          .get()
          .then((ss) =>
            ss.ref.set(
              {
                [mealtype.id || mealtype.title]:
                  idx > -1
                    ? ss
                      .data()
                    [mealtype.id || mealtype.title].map((x, i) =>
                      i == idx ? food : x
                    )
                    : [
                      ...((ss.data() || {})[mealtype.id || mealtype.title] ||
                        []),
                      ...(Array.isArray(food) ? food : [food])
                    ]
              },
              { merge: true }
            )
          ),
        raw &&
        value?.ref.set(
          {
            recentFoods: [
              raw,
              ...(value?.recentFoods || []).filter((x) =>
                typeof food.id === 'object'
                  ? x.title != food.title
                  : x.id != food.id
              )
            ]
          },
          { merge: true }
        )
      ])
      setPickMealtype(null)
      setState({ tab: 0, mealtype: null })
      Router.push('/mealplan')
    },
    [state.mealtype, state.date, value, date]
  )

  const pickMeal = useCallback(
    (mealtype) =>
      setState((prev) => ({ ...prev, modal: true, mealtype, date })),
    [setState, date]
  )

  const mealRef = useMemo(
    () =>
      value?.ref
        .collection('meals')
        .doc((state.date || date).format('YYYY-MM-DD')),
    [value?.ref?.path, (state.date || date).format('YYYY-MM-DD')]
  )

  const { value: _newMeals } = useDocument(mealRef)
  const newMeals = useMemo(() => {
    const { id, ...rest } = _newMeals || {}
    return rest
  }, [_newMeals])

  const [meals, setMeals] = useState({})

  useEffect(() => {
    let cancelled = false
    setMeals(
      Object.entries(value?.meals?.[date.format('YYYY-MM-DD')] || {}).reduce(
        (acc, [key, val]) =>
          val?.path
            ? acc
            : {
              ...acc,
              [acc[key]]: [val, ...(acc[key] || [])]
            },
        Object.fromEntries(
          Object.entries(newMeals || {}).map((mt) => [
            mt[0],
            mt[1].filter((m) => m && !m.path)
          ])
        )
      )
    )
      ; (() =>
        Promise.all(
          Object.values(value?.meals?.[date.format('YYYY-MM-DD')] || {})
            .concat(
              Object.values(newMeals || {}).reduce((a, v) => a.concat(v), [])
            )
            .filter((x) => x && x.path)
            .map((r) => r.get())
        ).then(
          (docs) => cancelled || console.log(docs)
          /* setMeals(
                                  Object.entries(
                                    value?.meals?.[date.format('YYYY-MM-DD')] || {}
                                  ).reduce(
                                    (acc, [key, val]) => ({
                                      ...acc,
                                      [acc[key]]: [
                                        val.path ? docs.find(d => d.ref.path === path)?.data() : val,
                                        ...(acc[key] || [])
                                      ]
                                    }),
                                    Object.fromEntries(
                                      Object.entries(newMeals || {}).map(mt => [
                                        mt[0],
                                        mt[1].map(x =>
                                          x.path ? docs.find(d => d.ref.path === x.path)?.data() : x
                                        )
                                      ])
                                    )
                                  )
                                ) */
        ))()

    return () => {
      cancelled = true
    }
  }, [newMeals, value])

  useEffect(() => {
    value?.ref
      .collection('meals')
      .where('title', '>', '')
      .get()
      .then((ss) =>
        ss.docs.forEach((doc) => {
          const { hidden, ingredients, mealtypes, title } = doc.data()
          console.log(title)
          if (!hidden) {
            value?.ref.collection('saved-meals').add({
              data: ingredients,
              name: title,
              date: new Date(),
              type: mealtypes?.[0]?.id || 1
            })
          }
          doc.ref.set({ title: null, oldTitle: title }, { merge: true })
        })
      )
  }, [value?.ref?.path])

  const val = useMemo(
    () => ({
      pickRecipe,
      selectedDiettype,
      setDiettype,
      selectedMealtype,
      setMealtype,
      pickMeal,
      ...state,
      date,
      meals,
      setState,
      setDate,
      mealtypes,
      diettypes,
      deleteMeal,
      addFood,
      setSearchmodal: (fnOrObj) =>
        setSearchmodal((prev) => {
          const next = typeof fnOrObj === 'function' ? fnOrObj(prev) : fnOrObj
          if (next) {
            return { unit: 'serving', units: { serving: 1 }, ...next }
          }
          return next
        })
    }),
    [
      pickRecipe,
      pickMeal,
      selectedDiettype,
      setDiettype,
      selectedMealtype,
      setMealtype,
      state,
      date,
      setState,
      setDate,
      mealtypes,
      diettypes,
      deleteMeal,
      addFood,
      meals,
      setSearchmodal
    ]
  )

  return (
    <>
      {pickMealtype && (
        <Backdrop>
          <Modal>
            <CloseButton
              onClick={() => {
                setPickMealtype(null)
                pickMealtype.reject()
              }}
            >
              <img
                src={Close}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </CloseButton>
            {mealtypes.map((mt, idx) => {
              const currentMeal =
                value?.meals?.[(state.date || date).format('YYYY-MM-DD')]?.[
                mt.id || mt.title
                ]
              return (
                <MealTypeSelectButton
                  currentMeal={currentMeal}
                  idx={idx}
                  mealtype={mt}
                  resolve={pickMealtype.resolve}
                />
              )
            })}
          </Modal>
        </Backdrop>
      )}
      {searchmodal && (
        <Backdrop>
          <Modal>
            <CloseButton onClick={() => setSearchmodal(null)}>
              <img
                src={Close}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </CloseButton>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: '2rem',
                width: '100%'
              }}
            >
              <h3
                style={{
                  fontSize: '11px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  color: '#939393'
                }}
              >
                INGREDIENT
              </h3>
              <Headline>{searchmodal.title}</Headline>

              <ModalInputField
                name="amount"
                placeholder="Amount"
                type="number"
                value={searchmodal.amount}
                onChange={(e) => {
                  const v = e.target.value
                  setSearchmodal((prev) => ({
                    ...prev,
                    amount: v
                  }))
                }}
              />
              <Select
                name="unit"
                style={{
                  background: '#2C2C2C',
                  color: '#ccc'
                }}
                value={searchmodal.unit}
                wrapperStyle={{ width: '100%' }}
                onChange={(e) => {
                  const v = e.target.value
                  setSearchmodal((prev) => ({
                    ...prev,
                    amount:
                      Math.round(
                        (10 * (prev.amount * prev.units[prev.unit])) /
                        prev.units[v]
                      ) / 10,
                    unit: v
                  }))
                }}
              >
                {Object.keys(searchmodal.units).map((unit) => (
                  <option value={unit}>{unit}</option>
                ))}
              </Select>

              <IngredientsBottom>
                <MealItemName
                  style={{ textAlign: 'center', lineHeight: '1.5rem' }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {Math.round(
                      searchmodal.calories *
                      searchmodal.units[searchmodal.unit] *
                      Number(searchmodal.amount)
                    )}
                  </span>
                  <br />
                  Calories
                </MealItemName>
                <MealItemName
                  style={{ textAlign: 'center', lineHeight: '1.5rem' }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {Math.round(
                      searchmodal.protein *
                      searchmodal.units[searchmodal.unit] *
                      Number(searchmodal.amount)
                    )}
                    g
                  </span>
                  <br />
                  Protein
                </MealItemName>
                <MealItemName
                  style={{ textAlign: 'center', lineHeight: '1.5rem' }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {Math.round(
                      searchmodal.carbs *
                      searchmodal.units[searchmodal.unit] *
                      Number(searchmodal.amount)
                    )}
                    g
                  </span>
                  <br />
                  Carbs
                </MealItemName>
                <MealItemName
                  style={{ textAlign: 'center', lineHeight: '1.5rem' }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {Math.round(
                      searchmodal.fat *
                      searchmodal.units[searchmodal.unit] *
                      Number(searchmodal.amount)
                    )}
                    g
                  </span>
                  <br />
                  Fat
                </MealItemName>
              </IngredientsBottom>

              <ProgressButton
                onClick={() => {
                  const { onSuccess, ...rest } = searchmodal
                  searchmodal.onSuccess(rest)
                  setSearchmodal(null)
                }}
              >
                Add
              </ProgressButton>
            </div>
          </Modal>
        </Backdrop>
      )}
      {state.modal && (
        <Backdrop>
          {state.modal === 'inputMealName' ? (
            <Modal>
              <CloseButton
                onClick={() => setState((prev) => ({ ...prev, modal: false }))}
              >
                <img
                  src={Close}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </CloseButton>
              <span
                style={{
                  color: '#939393',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  marginBottom: '5px',
                  display: 'block',
                  textAlign: 'center'
                }}
              >
                SAVE MEAL
              </span>
              <h3
                style={{
                  fontSize: '18px',
                  lineHeight: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                Name your Meal
              </h3>
              <ModalInputField
                name="name"
                placeholder="enter meal name here"
                style={{ margin: '22px 0' }}
                value={state.name}
                onChange={(e) => {
                  const v = e.target.value
                  setState((prev) => ({
                    ...prev,
                    name: v
                  }))
                }}
              />
              <Meal open meal={meals[state.mealtype.id]} />
              <MealButton
                disabled={!state.name}
                style={{
                  width: '100%',
                  background: '#842008',
                  opacity: state.name ? 1 : 0.5
                }}
                onClick={() => {
                  return value?.ref
                    ?.collection('saved-meals')
                    .add({
                      data: meals[state.mealtype.id],
                      name: state.name,
                      type: state.mealtype.id,
                      date: new Date()
                    })
                    .then(() => setState((prev) => ({ ...prev, modal: false })))
                }}
              >
                Save Meal
              </MealButton>
            </Modal>
          ) : (
            <Modal style={{ background: '#2c2c2c' }}>
              <CloseButton
                onClick={() => setState((prev) => ({ ...prev, modal: false }))}
              >
                <img
                  src={Close}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </CloseButton>
              <>
                <MealButton
                  style={{ color: 'white', background: 'black' }}
                  onClick={() => {
                    setState((prev) => ({ ...prev, modal: 'inputMealName' }))
                  }}
                >
                  Save this meal
                </MealButton>
                <MealButton
                  style={{ color: 'white', background: '#444' }}
                  onClick={() => {
                    setState((prev) => ({ ...prev, modal: false }))
                    Router.push('/mealhistory')
                  }}
                >
                  Choose from Saved Meals
                </MealButton>
                <MealButton
                  onClick={() => {
                    value?.ref
                      ?.collection('meals')
                      .doc((state.date || date).format('YYYY-MM-DD'))
                      .set(
                        {
                          [state.mealtype.id]: []
                        },
                        { merge: true }
                      )
                    setState((prev) => ({ ...prev, modal: false }))
                  }}
                >
                  Remove All Items
                </MealButton>
              </>
            </Modal>
          )}
        </Backdrop>
      )}
      <Context.Provider value={val}>{children}</Context.Provider>
    </>
  )
}
